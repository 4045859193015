import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { useNavigation } from '../../contexts/navigation';

import './side-navigation-menu.scss';

import * as events from 'devextreme/events';

export default function (props) {

  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;



  const { navigationData: { currentPath } } = useNavigation();

  const treeViewRef = useRef();
  const wrapperRef = useRef();


  const getWrapperRef = useCallback((element) => {
    const prevElement = wrapperRef.current;
    if (prevElement) {
      events.off(prevElement, 'dxclick');
    }

    wrapperRef.current = element;
    events.on(element, 'dxclick', e => {
      openMenu(e);
    });
  }, [openMenu]);

  const navigationItem = JSON.parse(sessionStorage.getItem('navigationItem'));
  const [navigation, setNavigation] = useState(navigationItem);

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;

    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      if (currentPath === "/profile") {
        treeView.unselectAll();
      } else {
        treeView.selectItem(currentPath);
        treeView.expandItem(currentPath);
      }
    }

    if (compactMode) {
      treeView.collapseAll();
    }

  }, [currentPath, compactMode]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={navigation}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>
      <div className={'lower-container-side-menu'}></div>      
    </div>
  );
}
