import React from "react";
import "./home.scss";

export default () => (
	<div className={"home-container"}>
		<img
			src={"../../../FGSS_Image.png"}
			alt="Sumitomo Homepage"
			className={"home-image"}
		/>
		<div className={"lower-container"}></div>
	</div>
);
